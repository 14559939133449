.data-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: 100%;

  &.horizontal {
    flex-direction: row;

    .data-item-header {
      font-size: $font-medium;
      margin-right: 8px;
    }
  }

  &:not(.horizontal) {
    .data-item-header {
      margin-bottom: 12px;
      font-size: $font-extra-small;
    }
  }

  &-header {
    color: $color-white;
    opacity: 0.6;
    text-align: center;
  }

  &-data {
    font-size: $font-medium;
    color: $color-white;
  }

  @media (max-width: 768px) {
    justify-content: space-between;

    & + & {
      margin-top: 16px;
    }

    &-header,
    &-data {
      margin-bottom: 0 !important;
      font-size: $font-small !important;
    }
  }
}
