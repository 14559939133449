.section-details {
  color: $color-white;
  margin-bottom: 48px;
  @include flex-between();
  flex-direction: column;

  &-header {
    text-transform: uppercase;
    font-size: 26px;
    letter-spacing: 0;
    font-weight: $font-bold;
    line-height: 1;
    margin-bottom: 22px;

    span {
      color: $color-geco;
    }
  }

  &-description {
    text-align: center;
    max-width: 60%;
  }

  @media (max-width: 768px) {
    &-description {
      max-width: unset;
    }
  }
}
