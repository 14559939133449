@mixin flex-centered() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin flex-between() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin font-extra-small() {
  font-size: $font-extra-small;
  // line-height: $line-extra-small;
}

@mixin font-small() {
  font-size: $font-small;
  // line-height: $line-small;
}

@mixin font-medium() {
  font-size: $font-medium;
  // line-height: $line-medium;
}

@mixin font-large() {
  font-size: $font-large;
  // line-height: $line-large;
}

@mixin dark-background-with-backdrop() {
  background-color: $color-dark;
  backdrop-filter: blur(4px);
}
