.info-card {
  position: relative;

  &-header {
    font-size: $font-extra-large;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
  }

  &-description {
    opacity: 0.6;
    font-size: $font-medium;
    text-align: center;
  }

  &-image {
    position: absolute;
    width: 300px;
    left: -100px;
    top: 75px;
  }

  @media (max-width: 992px) {
    &-image {
      left: -50px;
      top: 75px;
    }
  }

  @media (max-width: 768px) {
    &-image {
      display: none;
    }
  }
}
