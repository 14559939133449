.staking-pool {
  & + & {
    margin-top: 40px;
  }

  &.single-asset-staking {
    .staking-pool-header-type {
      color: $color-orange;
    }
  }

  &.lp-staking {
    .staking-pool-header-type {
      color: $color-blue;
    }
  }

  &-header {
    font-size: $font-medium;
    margin-bottom: 24px;

    &-details {
      @include flex-between();
    }

    &-type {
      font-weight: $font-bold;
      font-size: $font-extra-large;
      text-transform: uppercase;
      margin-bottom: 20px;

      .symbol {
        color: $color-white;
        margin-left: 8px;
      }
    }

    &-link {
      font-family: Roboto;
      font-weight: $font-regular;
      color: $color-geco;

      &:hover {
        color: $color-geco-hover;
      }

      &-icon {
        margin-left: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    &-header {
      &-type {
        text-align: center;
      }
    }
  }
}
