.footer {
  background-color: $color-footer;
  padding-top: 24px;

  &-logo {
    text-align: left;
  }

  &-content {
  }

  &-copyrights {
  }
}
