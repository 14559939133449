.bold {
  font-weight: $font-bold;
}

.geco {
  color: $color-geco;
}

.white {
  color: $color-white;
  opacity: 1;
}

.flex-container {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}
