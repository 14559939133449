.vesting-pool {
  font-family: Roboto;

  &.card {
    min-height: 200px;
    border: 1px solid $color-blue;
  }

  & + & {
    margin-top: 24px;
  }

  &-name {
    font-weight: $font-bold;
    margin-bottom: 28px;
  }

  &-details {
    @include flex-between();
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    &-name,
    &-details {
      text-align: center;
    }

    &-details {
      flex-direction: column;
    }

    &-button {
      margin-top: 24px !important;
    }
  }
}
