.header {
  &-wallet-section {
    @include flex-centered();

    .wallet-address {
      cursor: pointer;
      transition: $main-transition;

      .fa-wallet {
        margin: 0 8px 0 16px;
        color: $color-geco;
      }

      &:hover {
        color: $color-geco-hover;
        transform: scale(1.05);
      }
    }

    .navbar-wrap {
      flex-grow: 0;
      text-align: right;
    }
  }

  @media (max-width: 991.98px) {
    &-wallet-section {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      color: #fff;
      display: block;
      float: left;
      margin: 0;
      padding: 10px 5%;
      text-align: left;
      text-decoration: none;
      text-transform: capitalize;
      width: 90% !important;
      font-size: 16px;

      .wallet-address {
        .fa-wallet {
          margin: 0 8px 0 0;
        }
      }
    }
  }
}
