@import './styles/index';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Oxanium', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-app {
  height: calc(100vh);
  // TODO: Fix problem with images, adjust background
  background-image: url(./background1.jpeg);
  background-attachment: scroll;
  overflow-y: scroll;
  background-size: cover;
}

.app-content {
  box-sizing: border-box;
  padding-top: $header-height;
  margin: 50px 0;
  // padding-bottom: 50px;
  // TODO: Adjust RWD
  min-height: calc(100vh - 120px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
