.home {
  @include flex-column();
  font-family: Roboto;

  &-header {
    @include flex-column();
    align-self: center;
    font-size: 16px;
    margin-bottom: 48px;

    &-logo {
      margin-bottom: 16px;
      align-self: center;
    }

    &-text {
      max-width: 75%;
      align-self: center;
    }
  }

  &-section {
    @include flex-centered();

    & + & {
      margin-top: 80px;
    }

    &-info {
      &-header {
        font-size: 48px;
        line-height: 48px;

        .geco {
          margin-top: 8px;
        }
      }

      &-content {
        display: flex;

        &-description {
          margin-top: 24px;
          margin-bottom: 24px;
          font-size: $font-medium;
        }
      }
    }

    &-image {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    &.staking {
      .home-section-info {
        margin-right: 24px;
      }
    }

    &.vesting {
      .home-section-info {
        margin-left: 24px;
        text-align: right;
      }
    }
  }
}
