@mixin btn-before($color) {
  content: '';
  position: absolute;
  right: -13px;
  top: 0;
  height: 100%;
  width: 5px;
  transform: skewX(-16deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  left: auto;
  background-color: $color;
}

@mixin btn-after($color) {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: skewX(-16deg);
  z-index: -1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  left: auto;
  background-color: $color;
}

.btn {
  font-family: 'Oxanium', cursive;
  font-size: 14px;

  &.btn-style-two,
  &.btn-style-three {
    background: transparent;
    padding: 15px 35px;
    margin: 0 8px;
  }

  &.btn-style-three {
    // &:hover {
    //   &::before {
    //     @include btn-style-three-before;
    //     background-color: $color-aquamarine-hover;
    //   }

    //   &::after {
    //     @include btn-style-three-after;
    //     background-color: $color-aquamarine-hover;
    //   }
    // }

    &::before {
      @include btn-before($color-aquamarine);
    }

    &::after {
      @include btn-after($color-aquamarine);

      &:hover {
        background-color: $color-aquamarine-hover;
      }
    }

    &:not([disabled]):hover::before {
      right: 0;
    }
  }
}

.header-four-wrap {
  &.sticky-menu {
    background-color: #010b1a;
  }
}

.mean-container .mean-nav {
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oxanium', cursive;
  color: $color-white;
  margin-top: 0;
  font-style: normal;
  font-weight: $font-bold;
}
