.staking-pool-item {
  font-family: Roboto;

  & + & {
    margin-top: 24px;
  }

  .card:first-child {
    .single-asset-staking & {
      border-color: $color-orange;
    }

    .lp-staking & {
      border-color: $color-blue;
    }
  }

  &-header {
    @include flex-between();
    flex-basis: 100%;
    font-style: normal;
    font-size: $font-small;

    .accordion-icon {
      margin-right: 8px;

      .single-asset-staking & {
        color: $color-orange;
      }

      .lp-staking & {
        color: $color-blue;
      }

      &.fa-chevron-down {
        transition: $main-transition;
      }

      &.fa-chevron-down.rotated {
        transform: scaleY(-1);
      }
    }

    .name {
      font-weight: $font-bold;
      flex-basis: 100%;
    }

    .apy {
      font-weight: $font-bold;

      .single-asset-staking & {
        color: $color-orange;
      }

      .lp-staking & {
        color: $color-blue;
      }
    }

    .total {
      color: $color-white;
      font-weight: $font-regular;
      flex-basis: 100%;
      text-align: right;
    }
  }

  &-body {
    .staking-pool-item-details {
      @include flex-between();

      & + .staking-pool-item-details {
        margin-top: 16px;
      }
    }

    .pills {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .tabs {
      .tab-content {
        font-family: Roboto;
        font-size: $font-small;
        color: $color-white;

        .approve-section {
          @include flex-between();
        }

        .approve-section,
        .stake-section,
        .unstake-section {
          &-description {
            margin-bottom: 24px;
          }

          &-actions {
            @include flex-between();
          }
        }

        .stake-section,
        .unstake-section {
          .left {
            display: flex;
          }

          .right {
            flex-grow: 1;
            padding-left: 24px;
          }
        }

        .claim-section {
          @include flex-between();
          padding: 12px 16px;
          background: $color-pill-background;

          &-info {
            font-family: Roboto;
            padding: 0 32px;

            &-header {
              font-size: $font-medium;
            }

            &-details {
              font-size: $font-small;
              color: $color-white;

              .text {
                opacity: 0.6;
                margin-right: 4px;
              }

              .amount {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    &-header {
      flex-wrap: wrap;

      .name,
      .apy {
        flex-basis: 50%;
      }

      .apy {
        text-align: right;
      }

      .total {
        margin-top: 12px;
        text-align: left;
      }
    }

    &-details {
      flex-wrap: wrap;

      .data-item {
        flex-direction: row;
      }
    }

    &-body {
      .tabs {
        .tab-content {
          .claim-section {
            &-image {
              display: none;
            }
          }

          .approve-section,
          .stake-section,
          .unstake-section {
            &-actions {
              flex-wrap: wrap-reverse;
            }

            .left,
            .right {
              width: 100%;
            }

            .left {
              margin-top: 16px;
            }

            .right {
              padding-left: 0;
            }

            .playnity-input-wrapper {
              width: 50%;

              .playnity-input {
                width: 100%;
              }
            }

            .btn {
              width: 50%;
            }
          }

          .claim-section {
            flex-wrap: wrap;

            &-info {
              padding: 0;
              margin-bottom: 16px;
            }

            &-button {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
